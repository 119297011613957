@import "../../utilities/variables";
.tpk-modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    background: rgba(0, 0, 0, .5);
    height: 100vh;
    z-index: 1000;
    a, .tpk-button--link {
        font-weight: 400;
        text-decoration: underline;
    }
    &.active {
        display: block;
    }
    & > div.active {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        height: 100%;
        width: 100%;
        .tpk-modal {
            &__content {
                background-color: var(--white);
                max-width: 100%;
                width: 45rem;
                border-radius: 1rem;
                padding: 1rem 1rem 2rem 1rem;
                max-height: 100vh;
                overflow: auto;
            }
            &__header {
                position: relative;
                padding: 1rem 4rem 2rem 1rem;
                &__title {
                    font-size: 1.5rem;
                    line-height: 2rem;
                    font-weight: 600;
                }
                .tpk-modal__close {
                    position: absolute;
                    top: 1rem;
                    right: 1rem;
                    color: var(--primary);
                    background-color: transparent;
                    border: none;
                    cursor: pointer;
                    &:hover {
                        color: var(--secondary);
                    }
                }
            }
        }
    }
    &__footer {       
        padding: 0 1rem; 
        display: flex;
        gap: 1rem;
        justify-content: center;
        * {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(50% - .5rem);
            justify-content: center;
            white-space: nowrap;
            @media #{$breakpoint-sm-up} {
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: 28%;
            }
        }
        &--column {
            flex-direction: column-reverse;
            @media #{$breakpoint-sm-up} {
                flex-direction: row;
            }
        }
        & + div {
            margin-top: 2rem;
            color: var(--primary);
            & > span {
                display: none;
                @media #{$breakpoint-sm-up} {
                        display: inline;
                }
            }
            @media #{$breakpoint-md-up} {
                margin-top: 3rem;
                & > span {
                    display: inline;
                }
            }
        }
    }
}